.container {
  display: flex;
  align-items: center;
  position: relative;
  margin: 50px 0 26px 0;
}

#input {
  position: absolute;
  height: 38px;
  margin: unset;
  letter-spacing: 0px;
  font-size: 11px;
  padding: 14px 30px 15px 7px;
  text-align: center !important;
  background-color: #f5f5f5;
}

@media (min-width: 480px) {
  #input {
    text-align: center;
  }
}

#button {
  position: absolute;
  width: unset;
  right: 0;
  height: 38px;
  margin: unset;
  padding: unset;
  padding: 0 20px 0 20px;
  background-color: #1c1b1b;
}
