@font-face {
  font-family: "Aktiv-Grotesk";
  src: url("https://vehlaeyewear.com/cdn/shop/t/168/assets/AktivGrotesk_Md.woff2")
    format("woff2");
}

@font-face {
  font-family: "Aktiv-Grotesk";
  src: url("https://vehlaeyewear.com/cdn/shop/t/168/assets/AktivGroteskVF_Wght.woff2")
    format("woff2");
  font-weight: 400;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: "Aktiv-Grotesk";
  src: url("https://vehlaeyewear.com/cdn/shop/t/168/assets/AktivGrotesk_Md.woff2")
    format("woff2");
  font-weight: 500;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: "Aktiv-Grotesk";
  src: url("https://vehlaeyewear.com/cdn/shop/t/168/assets/AktivGrotesk_Bd.woff2")
    format("woff2");
  font-weight: 700;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: "Proxima-Nova-Regular";
  src: url("https://vehlaeyewear.com/cdn/shop/t/161/assets/ProximaNovaRegular.woff2")
    format("woff2");
  font-weight: 400;
  font-style: normal;
  font-display: swap;
}

body {
  padding-top: 10px;
  font-family: "Aktiv-Grotesk", sans-serif;
  text-align: center;
  background-color: #fff;
  font-weight: 500;
}

a {
  color: #000;
}

hr {
  border: 0;
  border-bottom: 1px solid #e5e5e5;
}

.container {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin: 0 auto;
}

button {
  cursor: pointer;
  display: block;
  margin: 4px 0 20px 0;
  color: #fff;
  border-color: #000;
  border-radius: 100px;
  background-color: #000;
  padding: 12px 14px;
  width: 100%;
  border: 1px solid transparent;
  font-size: 11px;
  height: 40px;
  font-weight: 700;
}

#confirmation-screen {
  margin-top: 18px;
  font-size: 13px;
  font-weight: 500;
}

#confirmation-screen p {
  margin: 24px 0;
  font-size: 11px;
}

#error-box {
  font-size: 11px;
  border-radius: 4px;
  padding: 4px 12px;
  color: #ffffff;
  background-color: #c61212;
  margin: 10px 0 20px 0;
}

.loading-spinner {
  position: absolute;
  top: 60%;
  right: 0;
  bottom: 0;
  left: 50%;

  -webkit-animation-duration: 0.75s;
  -moz-animation-duration: 0.75s;
  animation-duration: 0.75s;
  -webkit-animation-iteration-count: infinite;
  -moz-animation-iteration-count: infinite;
  animation-iteration-count: infinite;
  -webkit-animation-name: rotate-forever;
  -moz-animation-name: rotate-forever;
  animation-name: rotate-forever;
  -webkit-animation-timing-function: linear;
  -moz-animation-timing-function: linear;
  animation-timing-function: linear;
  height: 24px;
  width: 24px;
  border: 4px solid #000;
  border-right-color: transparent;
  border-radius: 50%;
}

@-webkit-keyframes rotate-forever {
  0% {
    -webkit-transform: rotate(0deg);
    -moz-transform: rotate(0deg);
    -ms-transform: rotate(0deg);
    -o-transform: rotate(0deg);
    transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(360deg);
    -moz-transform: rotate(360deg);
    -ms-transform: rotate(360deg);
    -o-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}

@-moz-keyframes rotate-forever {
  0% {
    -webkit-transform: rotate(0deg);
    -moz-transform: rotate(0deg);
    -ms-transform: rotate(0deg);
    -o-transform: rotate(0deg);
    transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(360deg);
    -moz-transform: rotate(360deg);
    -ms-transform: rotate(360deg);
    -o-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}

@keyframes rotate-forever {
  0% {
    -webkit-transform: rotate(0deg);
    -moz-transform: rotate(0deg);
    -ms-transform: rotate(0deg);
    -o-transform: rotate(0deg);
    transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(360deg);
    -moz-transform: rotate(360deg);
    -ms-transform: rotate(360deg);
    -o-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}

#subscribe-form {
  font-size: 13px;
  letter-spacing: 0.05em;
  width: 100%;
  text-align: left;
  margin-top: 28px;
}

input[type="text"] {
  padding: 10px 30px 10px 30px;
  border: 1px solid black;
  border-radius: 30px;
  box-sizing: border-box;
  width: 100%;
  margin: 6px 0 10px 0;
}

input::placeholder {
  text-align: center !important;
}

input:focus,
input:focus-visible {
  outline: 1px solid black;
  text-align: left !important;
}

#policies,
#policies a {
  font-size: 10px;
  color: #3d3d3d;
  text-align: center;
}

#info-text {
  font-size: 11px;
  text-align: center;
  margin: 40px 0 28px 0;
  font-weight: 500;
}

@media (min-width: 768px) {
  .container {
    max-width: 730px;
  }

  #confirmation-screen {
    width: 450px;
  }

  input[type="text"] {
    padding: 10px 60px 10px 60px;
  }
}

#bag-img {
  width: 320px;
  border-radius: 4px;
  position: relative;
}

label {
  margin-left: 4px;
  font-size: 11px;
  text-align: left;
  font-weight: 400;
  letter-spacing: 0px;
}

.width-container {
  max-width: 430px;
  margin-top: 28px;
}

h1 {
  font-size: 18px;
  font-weight: 500;
}

#want-to {
  font-size: 14px !important;
  font-weight: 600;
}

#max-value {
  margin-top: -8px;
}

::placeholder {
  color: #8b8b8b;
  opacity: 1; /* Firefox */
  font-size: 11px;
  font-weight: 400;
}

::-ms-input-placeholder {
  /* Edge 12 -18 */
  color: #8b8b8b;
}

#name-container {
  display: flex;
  gap: 8px;
}

#winner-announce {
  margin-top: -22px !important;
}

#w-100 {
  font-weight: 100;
}
