.Countdown {
  margin: 28px auto;
}

.Countdown-col {
  display: inline-block;
}

.Countdown-col-element {
  display: inline-block;
  padding: 0 14px;
  display: flex;
  flex-direction: column;
}

.Countdown-col-element strong {
  font-size: 18px;
}

span {
  font-size: 14px;
}

.time {
  color: #6c6c6c;
  font-size: 12px;
}
